import { useState, useEffect } from "react";
import { Create, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Select,
    ButtonProps,
    Col,
    Row,
    TimePicker,
    DatePicker,
    AutoComplete,
    Typography,
    Upload
} from "antd";
import { RcFile } from "antd/lib/upload/interface";

import { useList } from "@refinedev/core";
import { IWork, IWorkStatus, IUser } from "../../interfaces";
import { supabaseClient, normalizeFile } from "../../utility";
import debounce from "lodash/debounce";

type CreateWorkProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    initFormValues: Partial<IWork>;
};

interface IOptionGroup {
    value: number;
    name: string;
    mobile: string;
    location: string;
    label: string | React.ReactNode;
}

interface IOptions {
    options: IOptionGroup[];
}

export const CreateWork: React.FC<CreateWorkProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    initFormValues
}) => {

    const { Text } = Typography;

    // const [form] = Form.useForm();

    const { selectProps: assignedUserSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "full_name",
        filters: [
            {
              field: "role",
              operator: "eq",
              value: "technician",
            },
          ],

        pagination: {
            mode: "server"
        }
    });

    const renderItem = (id: number, name: string, mobile: string, location: string) => ({
        value: id,
        name: name,
        mobile: mobile,
        location: location,
        label: (
            <Text>{name} | {mobile} | {location}</Text>
        ),
    });

    const [value, setValue] = useState<string>("");
    const [field, setField] = useState<string>("");
    const [options, setOptions] = useState<IOptions[]>([]);

    const { refetch: refetchWorks } = useList<IWork>({
        resource: "works",

        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const orderOptionGroup = data.data.map((item) =>
                    renderItem(
                        item.id, item.cust_name, item.mobile, item.location
                    ),
                );
                if (orderOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            options: orderOptionGroup,
                        },
                    ]);
                }
            },
        },

        filters: field != "" ? [{ field: field, operator: "startswith", value }] : []
    });

    const onSelect = (value: number) => {
        const work = options[0].options.find(w => w.value === value)
        formProps.form?.setFieldsValue({ cust_name: work?.name });
        formProps.form?.setFieldsValue({ mobile: work?.mobile });
        formProps.form?.setFieldsValue({ location: work?.location });
    };

    useEffect(() => {
        setOptions([]);
        refetchWorks();
    }, [value]);

    return (
        <Drawer
            {...drawerProps}
            width="500"
        >
            <Create resource="works" saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    // form={form}
                    layout="vertical"
                    initialValues={initFormValues}
                    onFinish={(values) => {
                        console.log(values)
                        return (
                            formProps.onFinish &&
                            formProps.onFinish({
                                ...values,
                                "start_date": values?.start_date?.format("YYYY-MM-DD"),
                                "start_time": values?.start_time?.format("HH:mm"),
                            })
                        );
                    }}
                >

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Customer Name"
                        name="cust_name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <AutoComplete
                            options={options}
                            filterOption={false}
                            onSelect={onSelect}
                            onSearch={debounce(
                                (value: string) => {
                                    setValue(value)
                                    setField("cust_name")
                                },
                                300,
                            )}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <AutoComplete
                            options={options}
                            filterOption={false}
                            onSelect={onSelect}
                            onSearch={debounce(
                                (value: string) => {
                                    setValue(value)
                                    setField("mobile")
                                },
                                300,
                            )}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="Location"
                        name="location"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <AutoComplete
                            options={options}
                            filterOption={false}
                            onSelect={onSelect}
                            onSearch={debounce(
                                (value: string) => {
                                    setValue(value)
                                    setField("location")
                                },
                                300,
                            )}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="Assigned To"
                        name="assigned_user"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select {...assignedUserSelectProps} getPopupContainer={trigger => trigger.parentElement} />
                    </Form.Item>

                    <Form.Item
                        name="work_type"
                        hidden={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Row gutter={10}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={"Scheduled Date"}
                                name="start_date"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={"Scheduled Time"}
                                name="start_time"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <TimePicker format={'HH:mm'} minuteStep={15} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Form.Item label="Invoice">
                        <Form.Item
                            name="invoice_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({
                                    file,
                                    onError,
                                    onSuccess,
                                }) => {
                                    const rcFile = file as RcFile;
                                    const fileUrl = `invoices/${rcFile.name}`;

                                    const { error } = await supabaseClient.storage
                                        .from("files")
                                        .upload(fileUrl, file, {
                                            cacheControl: "3600",
                                            upsert: true,
                                        });

                                    if (error) {
                                        console.log(error)
                                        return onError?.(error);
                                    }
                                    const { data } = await supabaseClient.storage
                                        .from("files")
                                        .createSignedUrl(fileUrl, 3600);

                                    onSuccess?.(
                                        { url: data?.signedUrl },
                                        new XMLHttpRequest(),
                                    );
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item> */}

                    <Form.Item label="Invoice / Quotation">
                        <Form.Item
                            name="invoice_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({ file, onError, onSuccess }) => {
                                    try {
                                        const rcFile = file as RcFile;
                                        const fileUrl = `invoices/${window.btoa( rcFile.name )}`;

                                        await supabaseClient.storage
                                            .from("files")
                                            .upload(fileUrl, file, {
                                                cacheControl: "3600",
                                                upsert: true,
                                            });

                                        const { data } = await supabaseClient.storage
                                            .from("files")
                                            .getPublicUrl(fileUrl);

                                        const xhr = new XMLHttpRequest();
                                        onSuccess && onSuccess({ url: data?.publicUrl }, xhr);
                                    } catch (error) {
                                        onError && onError(new Error("Upload Error"));
                                    }
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                    {/* <Form.Item label="Job Completion Form">
                        <Form.Item
                            name="job_completion_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({
                                    file,
                                    onError,
                                    onSuccess,
                                }) => {
                                    const rcFile = file as RcFile;
                                    const fileUrl = `job_completion/${rcFile.name}`;

                                    const { error } = await supabaseClient.storage
                                        .from("files")
                                        .upload(fileUrl, file, {
                                            cacheControl: "3600",
                                            upsert: true,
                                        });

                                    if (error) {
                                        console.log(error)
                                        return onError?.(error);
                                    }
                                    const { data } = await supabaseClient.storage
                                        .from("files")
                                        .createSignedUrl(fileUrl, 3600);

                                    onSuccess?.(
                                        { url: data?.signedUrl },
                                        new XMLHttpRequest(),
                                    );
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item> */}

                    <Form.Item label="Job Completion Form">
                        <Form.Item
                            name="job_completion_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({ file, onError, onSuccess }) => {
                                    try {
                                        const rcFile = file as RcFile;
                                        const fileUrl = `job_completion/${window.btoa( rcFile.name )}`;

                                        await supabaseClient.storage
                                            .from("files")
                                            .upload(fileUrl, file, {
                                                cacheControl: "3600",
                                                upsert: true,
                                            });

                                        const { data } = await supabaseClient.storage
                                            .from("files")
                                            .getPublicUrl(fileUrl);

                                        const xhr = new XMLHttpRequest();
                                        onSuccess && onSuccess({ url: data?.publicUrl }, xhr);
                                    } catch (error) {
                                        onError && onError(new Error("Upload Error"));
                                    }
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                </Form>
            </Create>

        </Drawer>
    );
};