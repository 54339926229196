export * from "./create";
export * from "./edit";
export * from "./workStatus";
export * from "./workType";
export * from "./filter";
export * from "./workCommentCreate";
export * from "./workItemCreate";
export * from "./workItemEdit";
export const workTypeColor = {
    1: '#BA3C3C',
    2: '#5FA051',
    3: '#3A6F9E'
};
