import React from "react"

import {
    useMany,
    getDefaultFilter,
    CrudFilters,
    HttpError,
    BaseRecord,
    useNavigation,
    useDelete,
} from "@refinedev/core";

import {
    List,
    TextField,
    DateField,
    useTable,
    useDrawerForm,
    CreateButton,
    getDefaultSortOrder,
    useSimpleList,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { CommentOutlined, MoreOutlined, PlusCircleOutlined, EyeOutlined, CloseCircleOutlined, EditOutlined } from "@ant-design/icons";

import {
    Table,
    Select,
    Col,
    Row,
    Card,
    Space,
    Grid,
    List as AntdList,
    Avatar,
    Typography,
    Menu,
    Dropdown,
    Badge
} from "antd";

import { Comment } from "@ant-design/compatible";

import { IUser, IWork, IWorkStatus, IWorkFilterVariables, IWorkComment, IWorkItem } from "../../interfaces";

import {
    CreateWork, EditWork, WorkStatus, Filter, CreateWorkComment, CreateWorkItem, WorkType
} from "../../components/works";

import moment from 'moment';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export type WorkType = 1 | 2 | 3;
const initFormValues: Partial<IWork> = {
    work_type: 1,
};

const { Title } = Typography;

export const WorkList: React.FC = () => {

    const breakpoint = Grid.useBreakpoint();

    const { show } = useNavigation();

    const { tableProps, searchFormProps, filters, setFilters, sorters: sorter } = useTable<
        IWork,
        HttpError,
        IWorkFilterVariables
    >({
        meta: {
            select: "*, work_status!inner(status_name), work_comments(count)",
        },

        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, timestamp, status_id, start_date, assigned_user } = params;

            // console.log(q);
            // console.log(filters);

            if (q != null && q != undefined && q != "") {
                filters.push({
                    operator: "or",
                    value: [
                        {
                            field: "description",
                            operator: "contains",
                            value: q != "" ? "%" + q + "%" : undefined, //have to put "%"+q+"%" else supabase ilike filter doesnt work
                        },
                        {
                            field: "cust_name",
                            operator: "contains",
                            value: q != "" ? "%" + q + "%" : undefined
                        },
                        {
                            field: "mobile",
                            operator: "contains",
                            value: q != "" ? "%" + q + "%" : undefined
                        },
                        {
                            field: "location",
                            operator: "contains",
                            value: q != "" ? "%" + q + "%" : undefined
                        },
                    ]
                })
            } else {
                filters.push({
                    operator: "or",
                    value: []
                })
            };

            // filters.push(
            //     {
            //         field: "description",
            //         operator: "contains",
            //         value: q,
            //     }
            // )

            filters.push(
                {
                    field: "timestamp",
                    operator: "gte",
                    value: timestamp
                        ? timestamp[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "timestamp",
                    operator: "lte",
                    value: timestamp
                        ? timestamp[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            filters.push({
                field: "status_id",
                operator: "eq",
                value: status_id,
            });

            filters.push({
                field: "work_status.status_name",
                operator: "ne",
                value: undefined,
            });

            filters.push(
                {
                    field: "start_date",
                    operator: "gte",
                    value: start_date
                        ? start_date[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "start_date",
                    operator: "lte",
                    value: start_date
                        ? start_date[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            filters.push({
                field: "assigned_user",
                operator: "eq",
                value: assigned_user,
            });

            return filters;
        },

        syncWithLocation: false,

        filters: {
            initial: [
                {
                    field: "work_status.status_name",
                    operator: "ne",
                    value: "Completed",
                },
                {
                    field: "work_type",
                    operator: "eq",
                    value: initFormValues.work_type,
                }
            ]
        },

        sorters: {
            initial: [
                {
                    field: "timestamp",
                    order: "desc",
                },
            ]
        }
    });

    const { mutate: mutateDelete } = useDelete();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editShow(id)
                }}
            >
                Edit
            </Menu.Item>
            <Menu.Item
                key="reject"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "works",
                        id,
                        mutationMode: "undoable",
                    });
                }}
            >
                Delete
            </Menu.Item>
            <Menu.Item
                key="show"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EyeOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    show("works", id);
                }}
            >
                Open
            </Menu.Item>
            <Menu.Item
                key="comment"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CommentOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => { createWorkCommentFormProps.form?.setFieldsValue({ work_id: id }); createWorkCommentShow() }}
            >
                Comment
            </Menu.Item>
            <Menu.Item
                key="item"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <PlusCircleOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => { createWorkItemFormProps.form?.setFieldsValue({ work_id: id }); createWorkItemShow() }}
            >
                Add Item
            </Menu.Item>
        </Menu>
    );

    const statusIds =
        tableProps?.dataSource?.map((item) => item.status_id) ?? [];
    const { data: statusData, isLoading: statusLoading } = useMany<IWorkStatus>({
        resource: "work_status",
        ids: statusIds,
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });

    const assignedUserIds =
        tableProps?.dataSource?.filter((item) => item?.assigned_user).map((item) => item.assigned_user) ?? [];
    const { data: assignedUserData, isLoading: assignedUserLoading } = useMany<IUser>({
        resource: "users",
        ids: assignedUserIds,
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });

    const createdByUserIds =
        tableProps?.dataSource?.filter((item) => item?.created_by).map((item) => item.created_by) ?? [];
    const { data: createdByUserData, isLoading: createdByUserLoading } = useMany<IUser>({
        resource: "users",
        ids: createdByUserIds,
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });

    const handleChange = (value: WorkType) => {
        createFormProps.form?.setFieldsValue({ work_type: value });
        setFilters([
            {
                field: "work_type",
                operator: "eq",
                value: value
            }
        ]);
    };

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IWork>({
        action: "create",
        resource: "works",
        redirect: false,
        autoResetForm: true,
        onMutationSuccess: (data) => {
            console.log({ data });
        },
        onMutationError: (data) => {
            console.log({ data });
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IWork>({
        action: "edit",
        resource: "works",
        redirect: false,
        autoResetForm: true,
    });

    const {
        drawerProps: createWorkCommentDrawerProps,
        formProps: createWorkCommentFormProps,
        saveButtonProps: createWorkCommentSaveButtonProps,
        show: createWorkCommentShow,
    } = useDrawerForm<IWorkComment>({
        action: "create",
        resource: "work_comments",
        redirect: false,
        autoResetForm: true,
    });

    const {
        drawerProps: createWorkItemDrawerProps,
        formProps: createWorkItemFormProps,
        saveButtonProps: createWorkItemSaveButtonProps,
        show: createWorkItemShow,
    } = useDrawerForm<IWorkItem>({
        action: "create",
        resource: "work_items",
        redirect: false,
        autoResetForm: true,
    });

    return (
        <Row gutter={[16, 16]}>
            {/* <Col
                xl={24}
                lg={24}
                xs={24}
                // style={{
                //     marginTop: "48px",
                // }}
            >
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col> */}

            <Col xl={24} xs={24}>
                <List
                    headerProps={{
                        extra: [
                            <Select
                                defaultValue={getDefaultFilter("work_type", filters)}
                                style={{ width: 120 }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 1,
                                        label: <WorkType type={1} />
                                    },
                                    {
                                        value: 2,
                                        label: <WorkType type={2} />
                                    },
                                    {
                                        value: 3,
                                        label: <WorkType type={3} />
                                    },
                                ]}
                            />,
                            <CreateButton onClick={() => createShow()}></CreateButton>
                        ]
                    }}
                >
                    <Card size='small' title="Filters" type="inner" style={{
                        marginBottom: "20px",
                    }}>
                        <Filter formProps={searchFormProps} />
                    </Card>
                    <Table {...tableProps}
                        rowKey="id"
                        scroll={{ x: 1200 }}
                        sticky={true}
                        onRow={(record) => {
                            return {
                                onDoubleClick: () => {
                                    show("works", record.id);
                                },
                            };
                        }}
                        expandable={{
                            expandedRowRender: !breakpoint.xs
                                ? expandedRowRender
                                : undefined,

                        }}
                    >
                        <Table.Column
                            dataIndex="work_comments"
                            key="count"
                            width={60}
                            render={(work_comments) => {
                                const count = work_comments[0]?.count || 0;
                                return (
                                    <span>
                                        {/* <CommentOutlined style={{ marginRight: 8 }} />
                                        {count} */}
                                        <Badge count={count} size="small" color={count==0 ? "silver" : "red"} showZero >
                                            <CommentOutlined style={{ marginRight: 8 }} />
                                        </Badge>
                                    </span>
                                );
                            }}
                        />
                        <Table.Column dataIndex="location" title="Location" fixed={'left'} />
                        <Table.Column dataIndex="cust_name" title="Name" width={120} fixed={'left'} />
                        <Table.Column dataIndex="mobile" title="Phone" width={85} />
                        <Table.Column dataIndex="description" title="Description" />
                        <Table.Column
                            dataIndex="status_id"
                            title="Status"
                            defaultFilteredValue={getDefaultFilter("status_id", filters)}
                            width={115}
                            render={(value) => {
                                if (statusLoading) {
                                    return <TextField value="Loading..." />;
                                }

                                return (
                                    <WorkStatus
                                        status={
                                            statusData?.data.find(
                                                (item) => item.id === value,
                                            )?.status_name!
                                        }
                                    />
                                );
                            }}
                        />
                        <Table.Column
                            dataIndex="assigned_user"
                            title="Assigned To"
                            render={(value) => {
                                if (assignedUserLoading) {
                                    return <TextField value="Loading..." />;
                                }

                                return (
                                    <TextField
                                        value={
                                            assignedUserData?.data.find(
                                                (item) => item.id === value,
                                            )?.full_name
                                        }
                                    />
                                );
                            }} />
                        <Table.Column
                            dataIndex={"start_date"}
                            title="Scheduled For"
                            width={140}
                            render={(date, row: IWork) => { 
                                if (date != null && date !== "" && row["start_time"] != null && row["start_time"] !== "") 
                                    return <DateField format="D MMM YYYY HH:mm" value={date + row["start_time"]} /> 
                                else if (date != null && date !== "")
                                    return <DateField format="D MMM YYYY" value={date} />
                            }}
                            sorter
                            defaultSortOrder={getDefaultSortOrder("start_date", sorter)}
                        />
                        <Table.Column
                            dataIndex="timestamp"
                            title="Created At"
                            width={120}
                            render={function (value) {
                                dayjs.extend(utc)
                                const originalTimezone = value?.slice(-6);
                                const offsetedDate = dayjs(value).utcOffset(originalTimezone); //disable converting to local timezone in dayjs 
                                return <DateField format="D MMM YYYY HH:mm" value={offsetedDate} />
                            }}
                            sorter
                            defaultSortOrder={getDefaultSortOrder("timestamp", sorter)}
                        />
                        <Table.Column
                            dataIndex="created_by"
                            title="Created By"
                            width={120}
                            render={(value) => {
                                if (createdByUserLoading) {
                                    return <TextField value="Loading..." />;
                                }

                                return (
                                    <TextField
                                        value={
                                            createdByUserData?.data.find(
                                                (item) => item.id === value,
                                            )?.full_name
                                        }
                                    />
                                );
                            }} />
                        <Table.Column<IWork>
                            width={80}
                            fixed="right"
                            title="Actions"
                            dataIndex="actions"
                            key="actions"
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />
                    </Table>
                    <CreateWork
                        drawerProps={createDrawerProps}
                        formProps={createFormProps}
                        saveButtonProps={createSaveButtonProps}
                        initFormValues={initFormValues}
                    />
                    <EditWork
                        drawerProps={editDrawerProps}
                        formProps={editFormProps}
                        saveButtonProps={editSaveButtonProps}
                    />
                    <CreateWorkComment
                        drawerProps={createWorkCommentDrawerProps}
                        formProps={createWorkCommentFormProps}
                        saveButtonProps={createWorkCommentSaveButtonProps}
                    />
                    <CreateWorkItem
                        drawerProps={createWorkItemDrawerProps}
                        formProps={createWorkItemFormProps}
                        saveButtonProps={createWorkItemSaveButtonProps}
                    />
                </List>
            </Col>
        </Row >
    );
};

const WorkCommentsList: React.FC<{ record: IWork }> = ({ record }) => {

    const //`useSimpleList` does not accept all of Ant Design's `List` component props anymore. You can directly use `List` component instead.,
        { listProps, queryResult } = useSimpleList<IWorkComment>({
            resource: "work_comments",
            meta: {
                select: "*, users(full_name)",
            },
            permanentFilter: [
                {
                    field: "work_id",
                    operator: "eq",
                    value: record.id,
                },
            ],
            hasPagination: false,
            syncWithLocation: false,
        });

    return (
        <AntdList {...listProps} className="comment-list" itemLayout='horizontal'
            header={<Title level={4}>{`${queryResult.data?.total} comments`}</Title>}
            renderItem={renderWorkComment} />
    );

};

const renderWorkComment = (item: IWorkComment) => {
    const { text } = item;

    return (
        <li>
            <Comment
                author={item.users.full_name}
                avatar={<Avatar src={item.users.avatar_url} />}
                content={<p>{text}</p>}
                datetime={moment(item.created_at).format('dddd, D MMM YYYY HH:mm')}
            />

        </li>
    );
};

const WorkItemsList: React.FC<{ record: IWork }> = ({ record }) => {
    const { tableProps, tableQueryResult } = useTable<IWorkItem>({
        resource: "work_items",

        meta: {
            select: "*, users(full_name)",
        },

        syncWithLocation: false,

        pagination: {
            mode: "off"
        },

        filters: {
            permanent: [
                {
                    field: "work_id",
                    operator: "eq",
                    value: record.id,
                },
            ]
        }
    });

    return (
        <List title={`${tableQueryResult.data?.total} items taken`}>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="code" title="Code" />
                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex={["users", "full_name"]} title="Added By" />
            </Table>
        </List>
    );
};

const expandedRowRender = (record: IWork) => {
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <WorkCommentsList record={record} />
            <WorkItemsList record={record} />
        </Space>
    );
};