import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Select,
    ButtonProps,
    DatePicker,
    TimePicker,
    Col,
    Row,
    Upload
} from "antd";
import { RcFile } from "antd/lib/upload/interface";

import { IWorkStatus, IUser } from "../../interfaces";
import { supabaseClient, normalizeFile } from "../../utility";

import dayjs from "dayjs";

type EditWorkProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const EditWork: React.FC<EditWorkProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
}) => {

    const { selectProps: statusSelectProps } = useSelect<IWorkStatus>({
        resource: "work_status",
        optionLabel: "status_name",

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: assignedUserSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "full_name",
        filters: [
            {
                field: "role",
                operator: "eq",
                value: "technician",
            },
        ],

        pagination: {
            mode: "server"
        }
    });

    return (
        <Drawer
            {...drawerProps}
            width="500"
        >
            <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{ extra: null }}
                resource="works"
            >
                <Form
                    {...formProps}
                    layout="vertical"
                >

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        name="status_id"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...statusSelectProps} getPopupContainer={trigger => trigger.parentElement} />
                    </Form.Item>

                    <Form.Item
                        label="Customer Name"
                        name="cust_name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Location"
                        name="location"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Assigned To"
                        name="assigned_user"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select {...assignedUserSelectProps} getPopupContainer={trigger => trigger.parentElement} />
                    </Form.Item>

                    <Form.Item
                        label="Work Type"
                        name="work_type"
                        // hidden={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                { value: 1, label: 'Fault' },
                                { value: 2, label: 'Survey' },
                                { value: 3, label: 'Project' },
                            ]}
                            getPopupContainer={trigger => trigger.parentElement}
                        />
                    </Form.Item>

                    <Row gutter={10}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={"Scheduled Date"}
                                name="start_date"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                getValueFromEvent={(onChange) => dayjs(onChange).format("YYYY-MM-DD")}
                                getValueProps={(value) => ({
                                    value: value ? dayjs(value) : "",
                                })}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={"Scheduled Time"}
                                name="start_time"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                getValueFromEvent={(onChange) => dayjs(onChange).format("HH:mm:ss")}
                                getValueProps={(value) => ({
                                    value: value ? dayjs(value, "HH:mm:ss") : "",
                                })}
                            >
                                <TimePicker format={'HH:mm'} minuteStep={15} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Invoice / Quotation">
                        <Form.Item
                            name="invoice_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({ file, onError, onSuccess }) => {
                                    try {
                                        const rcFile = file as RcFile;
                                        const fileUrl = `invoices/${window.btoa(rcFile.name)}`;

                                        await supabaseClient.storage
                                            .from("files")
                                            .upload(fileUrl, file, {
                                                cacheControl: "3600",
                                                upsert: true,
                                            });

                                        const { data } = await supabaseClient.storage
                                            .from("files")
                                            .getPublicUrl(fileUrl);

                                        const xhr = new XMLHttpRequest();
                                        onSuccess && onSuccess({ url: data?.publicUrl }, xhr);
                                    } catch (error) {
                                        onError && onError(new Error("Upload Error"));
                                    }
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label="Job Completion Form">
                        <Form.Item
                            name="job_completion_pdf"
                            valuePropName="fileList"
                            normalize={normalizeFile}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                listType="picture"
                                accept=".pdf"
                                multiple={false}
                                customRequest={async ({ file, onError, onSuccess }) => {
                                    try {
                                        const rcFile = file as RcFile;
                                        const fileUrl = `job_completion/${window.btoa(rcFile.name)}`;

                                        await supabaseClient.storage
                                            .from("files")
                                            .upload(fileUrl, file, {
                                                cacheControl: "3600",
                                                upsert: true,
                                            });

                                        const { data } = await supabaseClient.storage
                                            .from("files")
                                            .getPublicUrl(fileUrl);

                                        const xhr = new XMLHttpRequest();
                                        onSuccess && onSuccess({ url: data?.publicUrl }, xhr);
                                    } catch (error) {
                                        onError && onError(new Error("Upload Error"));
                                    }
                                }}
                            >
                                <p className="ant-upload-text">
                                    Drag & drop a file in this area
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                </Form>
            </Edit>

        </Drawer>
    );
};