import React, { useState } from 'react';
import { Badge, Calendar, Card, Col, List, Row, Space, Table, } from 'antd';
import type { Dayjs } from 'dayjs';
import './index.css';
import { useList, useMany } from '@refinedev/core';
import dayjs from 'dayjs';
import { IUser, IWork } from '../../../interfaces';
import { WorkType, workTypeColor } from '../../works';
import { ShowButton } from '@refinedev/antd';

export const ScheduleCalender: React.FC = () => {

  const [value, setValue] = useState(() => dayjs());
  const [selectedValue, setSelectedValue] = useState(() => dayjs());

  const onSelect = (newValue: Dayjs) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const onPanelChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const { data, isLoading, isError } = useList<IWork>({
    resource: "works",
    filters: [
      {
        field: "start_date",
        operator: "gte",
        value: dayjs(value).startOf("month").toISOString(),
      },
      {
        field: "start_date",
        operator: "lte",
        value: dayjs(value).endOf("month").toISOString(),
      },
    ],
    sorters: [
      {
        field: "start_date",
        order: "asc",
      },
      {
        field: "start_time",
        order: "asc",
      },
    ],
    pagination: {
      pageSize: 999, //there was no option to disable the pagination when I did it. By default it returns 10 records only
  },
  });

  const works = data?.data ?? [];

  const assignedUserIds =
    works.filter((item) => item?.assigned_user).map((item) => item.assigned_user) ?? [];
  const { data: assignedUserData, isLoading: assignedUserLoading } = useMany<IUser>({
    resource: "users",
    ids: assignedUserIds,
    queryOptions: {
      enabled: !!works,
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  const getWorksData = (value: Dayjs) => {

    let faultsCount: number = 0;
    let surveysCount: number = 0;
    let projectsCount: number = 0
    let worksList: IWork[] = []

    works
      .filter((work) => value.format('DD/MM/YYYY') == dayjs(work.start_date).format('DD/MM/YYYY'))
      .forEach(function (work) {
        if (work.work_type == 1) { faultsCount++ }
        else if (work.work_type == 2) { surveysCount++ }
        else if (work.work_type == 3) { projectsCount++ }

        worksList.push(work)
      });

    return { faultsCount, surveysCount, projectsCount, worksList };
  }

  const { worksList } = getWorksData(value);

  console.log(works)

//   var workTypeColor = {
//     'fault': '#f5222d',
//     'survey': '#228B22',
//     'project': '#0047AB',
//     1: '#f5222d',
//     2: '#228B22',
//     3: '#0047AB'
// };

  const dateCellRender = (value: Dayjs) => {

    let { faultsCount, surveysCount, projectsCount } = getWorksData(value);

    // console.log(projectsListData)
    return (
      <div className="events">
        {faultsCount ? <Badge count={faultsCount} color={workTypeColor[1]} size="small" /> : null}
        {surveysCount ? <Badge count={surveysCount} color={workTypeColor[2]} size="small" /> : null}
        {projectsCount ? <Badge count={projectsCount} color={workTypeColor[3]} size="small" /> : null}
      </div>
    );
  };

  return <Row>
    <Col span={17}>
      <Card>
        <Space size="large">
          <WorkType type={1}/>
          <WorkType type={2}/>
          <WorkType type={3}/>
        </Space>
        <Calendar
          dateCellRender={dateCellRender}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
        />
      </Card>
    </Col>


    <Col span={6} offset={1}>
      <Card title="Scheduled Works">
        <List
          dataSource={worksList}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={<Badge color={workTypeColor[item.work_type]} text={item.location} /> }
                description={                                            
                  `${assignedUserData?.data.find( (user) => user.id === item.assigned_user,)?.full_name} ${item.start_time ? `at ${item.start_time?.slice(0, -3)}` : ""}`
                }
              />
              <ShowButton hideText resource="works" recordItemId={item.id} />
            </List.Item>
          )}
        />
      </Card>
    </Col>
  </Row>;
};