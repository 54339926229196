import { Row, Col, Card, Typography } from "antd";

import {
} from "../../components/works";
import { ScheduleCalender } from "../../components/dashboard/scheduleCalender";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {

    return (<ScheduleCalender />);
};