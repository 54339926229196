import { useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, FormProps, Input, Select, DatePicker, Row, Col, Button, Space } from "antd";

import { IWorkStatus, IUser } from "../../interfaces";
import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";

export const Filter: React.FC<{ formProps: FormProps }> = (props) => {

    const { RangePicker } = DatePicker;

    const [expand, setExpand] = useState(false);

    const { selectProps: statusSelectProps } = useSelect<IWorkStatus>({
        resource: "work_status",
        optionLabel: "status_name",

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: userSelectProps } = useSelect<IUser>({
        resource: "users",
        optionLabel: "full_name",

        pagination: {
            mode: "server"
        }
    });

    return (
        <Form {...props.formProps}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label="Search" name="q">
                        <Input
                            placeholder="Search By"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Created At"
                        name="timestamp"
                    >
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Status"
                        name="status_id"
                    >
                        <Select
                            {...statusSelectProps} getPopupContainer={trigger => trigger.parentElement}
                            allowClear
                            // mode="multiple"
                            placeholder="Status"
                        />
                    </Form.Item>
                </Col>
                {expand && (
                    <>
                        <Col span={8}>
                            <Form.Item
                                label="Scheduled For"
                                name="start_date"
                            >
                                <RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Assigned To"
                                name="assigned_user"
                            >
                                <Select
                                    {...userSelectProps} getPopupContainer={trigger => trigger.parentElement}
                                    allowClear
                                    placeholder="Assigned To"
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col span={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            Filter
                        </Button>
                    </Form.Item>
                </Col> */}
                    </>
                )}
            </Row>
            <div style={{ textAlign: 'right' }}>
                <Space size="small">
                    <Button type="primary" htmlType="submit">
                        Filter
                    </Button>
                    <a
                        style={{ fontSize: 12 }}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        <DownOutlined rotate={expand ? 180 : 0} /> {expand ? "Collapse" : "Expand"}
                    </a>
                </Space>
            </div>
        </Form>
    );
};