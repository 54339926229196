import { Space, Badge } from "antd";

type WorkTypeProps = {
    type: 1 | 2 | 3;
};

export const WorkType: React.FC<WorkTypeProps> = ({ type }) => {
    let type_name, color;

    switch (type) {
        case 1:
            type_name = "Fault";
            color ='#BA3C3C'
            break;
        case 2:
            type_name = "Survey";
            color ='#5FA051';
            break;
        case 3:
            type_name = "Project";
            color = "#3A6F9E"
            break;
    }

    return <Badge color={color} text={type_name}/>;
};