import { Tag } from "antd";

type WorkStatusProps = {
    status: "Pending" | "Ongoing" | "Completed";
};

export const WorkStatus: React.FC<WorkStatusProps> = ({ status }) => {
    let color;

    switch (status) {
        case "Pending":
            color = "orange";
            break;
        case "Ongoing":
            color = "cyan";
            break;
        case "Completed":
            color = "green";
            break;
    }

    return <Tag color={color}>{status}</Tag>;
};