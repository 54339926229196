import { Create } from "@refinedev/antd";
import { Drawer, DrawerProps, Form, FormProps, Input, ButtonProps } from "antd";

type CreateWorkCommentProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateWorkComment: React.FC<CreateWorkCommentProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
}) => {

    return (
        <Drawer
            {...drawerProps}
            width="500"
        >
            <Create resource="work_comments" saveButtonProps={saveButtonProps} title="Create Comment">
                <Form
                    {...formProps}
                    layout="vertical"
                >

                    <Form.Item
                        name="text"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        name="work_id"
                        hidden={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Create>

        </Drawer>
    );
};