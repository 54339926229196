import { DevtoolsProvider, DevtoolsPanel } from "@refinedev/devtools";
import React from "react";

import { Refine, Authenticated, IResourceItem, Action } from "@refinedev/core";
import {
  notificationProvider,
  ThemedLayoutV2,
  ErrorComponent,
  AuthPage,
  RefineThemes,
} from "@refinedev/antd";

import { dataProvider, liveProvider } from "@refinedev/supabase";
import "@refinedev/antd/dist/reset.css";
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
  DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { supabaseClient } from "./utility";
import authProvider from "./authProvider";

import { DashboardPage } from "./pages/dashboard";
import { WorkList } from "./pages/works/list";
import { WorkShow } from "./pages/works/show";
// import { UserList } from "./pages/users/list";
import { Header, Title } from "./components";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ConfigProvider, notification } from "antd";
import { DashboardOutlined } from "@ant-design/icons";

const titleHandler = ({
  //show custome title instead of including refine in all titles
  resource,
  action,
  params,
}: {
  resource?: IResourceItem;
  action?: Action;
  params?: Record<string, string | undefined>;
}): string => {
  let title = "Custom default"; // Default title

  if (resource && action && params) {
    // title = `${resource.name} ${action} ${params.id}`;
    title = `${resource.name} ${action}`;
  }

  return title;
};

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider theme={RefineThemes.Blue}>
        <DevtoolsProvider>
          <Refine
            dataProvider={dataProvider(supabaseClient)}
            liveProvider={liveProvider(supabaseClient)}
            routerProvider={routerProvider}
            authProvider={authProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: "works",
                list: "/works",
                show: "/works/show/:id",
              },
              {
                name: "work_comments",
              },
            ]}
            notificationProvider={notificationProvider}
            /**
             * Multiple subscriptions are currently not supported with the supabase JS client v2 and @refinedev/supabase v4.
             * Therefore, enabling global live mode will cause unexpected behaviors.
             * Please set `liveMode: "auto"` or `liveMode: "manual"` manually while using real-time features of refine.
             */
            options={{
              liveMode: "off",
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key="authenticated-routes"
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <ThemedLayoutV2
                      Header={Header}
                      Title={Title}
                      initialSiderCollapsed={true}  
                      // OffLayoutArea={OffLayoutArea}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route
                  path="/update-password"
                  element={<AuthPage type="updatePassword" title={""} />}
                />

                <Route path="/works">
                  <Route index element={<WorkList />} />
                  <Route path="show/:id" element={<WorkShow />} />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated key="auth-pages" fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route
                  path="/login"
                  element={
                    <AuthPage
                      type="login"
                      title={""}
                      // providers={[
                      //   {
                      //     name: "google",
                      //     label: "Sign in with Google",
                      //     icon: (
                      //       <GoogleOutlined
                      //         style={{
                      //           fontSize: 18,
                      //           lineHeight: 0,
                      //         }}
                      //       />
                      //     ),
                      //   },
                      // ]}
                      formProps={{
                        initialValues: {
                          // email: "info@refine.dev",
                          // password: "refine-supabase",
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="/register"
                  element={<AuthPage type="register" title={""} />}
                />
                <Route
                  path="/forgot-password"
                  element={<AuthPage type="forgotPassword" title={""} />}
                />
              </Route>

              <Route
                element={
                  <Authenticated key="catch-all">
                    <ThemedLayoutV2>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={titleHandler} />
          </Refine>
          <DevtoolsPanel />
        </DevtoolsProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
