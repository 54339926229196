import { Create } from "@refinedev/antd";
import { Drawer, DrawerProps, Form, FormProps, Input, ButtonProps } from "antd";

type CreateWorkItemProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateWorkItem: React.FC<CreateWorkItemProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
}) => {

    return (
        <Drawer
            {...drawerProps}
            width="500"
        >
            <Create resource="work_items" saveButtonProps={saveButtonProps} title="Create Item">
                <Form
                    {...formProps}
                    layout="vertical"
                >

                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="work_id"
                        hidden={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Create>

        </Drawer>
    );
};