export const FineFoodsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
    props,
) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={68}
    height={27}
    viewBox="0 0 67 27"
    {...props}
  >
    <path
      d="M8.758 14.672h5.707c2.015-.094 1.976.73 1.937 2.473H8.641l.058 4.773c.45 2.465 1.797 3.566 3.766 3.734H24.62l-.058-2.93H13.207c-.988-.19-1.367-1.074-1.14-2.644h5.878c.996-.234 1.625-.61 1.711-1.61v-3.507c-1.312-5.188-11.043-3.707-10.898-.29Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#00adf1",
        fillOpacity: 1,
      }}
    />
    <path
      d="m60.598 19.45 2.21 3.484c.278.433.41.785.41 1.058 0 .442-.183.84-.546 1.195-.367.356-.777.532-1.23.532-.399 0-.75-.215-1.044-.649l-.168-.258-3.308-4.976-1.594 3.973c-.516 1.273-1.266 1.91-2.25 1.91-.41 0-.754-.13-1.035-.383a1.199 1.199 0 0 1-.418-.926c0-.273.074-.598.227-.96l.156-.387 6.097-15.141.157-.387c.437-1.074 1.136-1.61 2.093-1.61.399 0 .747.134 1.028.403.285.27.426.59.426.961 0 .29-.079.617-.227.988l-.156.395-3.637 9.012 3.203-2.399c.668-.496 1.235-.746 1.711-.746.399 0 .73.129 1 .387.266.258.399.578.399.96 0 .641-.352 1.223-1.055 1.755l-.328.242ZM48.012 22.602h1.492c1.207 0 1.809.425 1.809 1.28 0 .583-.208 1.036-.633 1.36-.422.328-1.016.492-1.774.492h-2.562c-.703 0-1.258-.168-1.668-.504-.406-.335-.614-.785-.614-1.347 0-.297.137-.79.415-1.48L50.23 8.136l.16-.387c.434-1.074 1.133-1.61 2.09-1.61.399 0 .739.133 1.02.403.277.27.422.59.422.96 0 .29-.074.614-.215.977l-.156.391ZM30.188 14.719h.445c1.203 0 1.808.426 1.808 1.277 0 .586-.214 1.04-.644 1.363-.426.328-1.016.489-1.766.489h-1.039l-1.906 4.718h1.594c1.207 0 1.808.426 1.808 1.278 0 .586-.207 1.039-.629 1.363-.425.328-1.02.488-1.777.488h-2.55c-.723 0-1.294-.164-1.72-.504-.421-.332-.632-.789-.632-1.359 0-.355.09-.746.258-1.176l.269-.633 1.684-4.175h-.215c-1.067 0-1.594-.414-1.594-1.246 0-.594.207-1.055.629-1.387.426-.328 1.016-.496 1.777-.496h.657l.753-1.883.157-.387c.445-1.062 1.152-1.59 2.11-1.59.386 0 .722.133 1.003.395.277.262.422.578.422.953 0 .367-.125.82-.371 1.379Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#bdbdbd",
        fillOpacity: 1,
      }}
    />
    <path
      d="M9.434 14.953a.92.92 0 0 1 0 1.84.913.913 0 0 1-.91-.918c0-.508.406-.922.91-.922ZM5.637 11.547c.683 0 1.238.558 1.238 1.25 0 .687-.555 1.246-1.238 1.246a1.243 1.243 0 0 1-1.239-1.246c0-.692.555-1.25 1.239-1.25ZM4.34 5.746c.797 0 1.441.649 1.441 1.45 0 .804-.644 1.452-1.441 1.452a1.448 1.448 0 0 1-1.442-1.453c0-.8.649-1.449 1.442-1.449ZM9.344 1.266c1 0 1.812.816 1.812 1.824a1.822 1.822 0 0 1-1.812 1.828c-1 0-1.813-.82-1.813-1.828s.813-1.824 1.813-1.824ZM15.64 3.227c1.204 0 2.184.984 2.184 2.199 0 1.21-.98 2.195-2.183 2.195a2.19 2.19 0 0 1-2.184-2.195c0-1.215.977-2.2 2.184-2.2Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#00adf1",
        fillOpacity: 1,
      }}
    />
    <path
      d="M36.867 14.52c-1.582 0-3.008.722-4.27 2.171-1.257 1.454-1.886 3.094-1.886 4.93 0 1.215.324 2.2.965 2.95.64.75 1.484 1.124 2.527 1.124h1.625c.668 0 1.352-.316 2.067-.957.292.64.851.957 1.667.957h1.825c.75 0 1.336-.164 1.765-.492.426-.332.641-.789.641-1.371 0-.844-.598-1.266-1.793-1.266h-1.57l2.05-5.109.157-.387c.148-.37.226-.691.226-.96 0-.391-.14-.72-.418-.989-.28-.27-.633-.402-1.05-.402-.766 0-1.391.36-1.86 1.078-.562-.852-1.45-1.277-2.668-1.277Zm1.297 4.675-1 2.485c-.258.64-.773.96-1.55.96h-.356c-.793 0-1.184-.495-1.184-1.48 0-.867.324-1.71.98-2.523.657-.813 1.333-1.219 2.024-1.219.356 0 .656.098.902.289.254.191.383.41.383.672 0 .219-.07.492-.199.816Zm0 0"
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#bdbdbd",
        fillOpacity: 1,
      }}
    />
  </svg>
);