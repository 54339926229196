import { useGetIdentity, useOne } from "@refinedev/core";

import { Avatar, Typography, Space, Grid, Row, Col, Layout as AntdLayout } from "antd";
import { IUser } from "../../interfaces";
import { FineFoodsIcon } from "../icons";

const { Header: AntdHeader } = AntdLayout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export const Header: React.FC = () => {
    const { data: auth_user } = useGetIdentity<{
        id: string;
    }>();

    const {data: public_user} = useOne({
        resource: "users",
        id: auth_user?.id,
        queryOptions: {enabled: !!auth_user }
    });
    
    const user = public_user?.data;

    console.log(public_user)
    const screens = useBreakpoint();

    // console.log(screens, screens.sm ? "space-between" : "end");

    return (
        <AntdHeader
            style={{
                padding: "0 24px",
                background: "white",
            }}
        >
            <Row
                align="middle"
                style={{
                    justifyContent: screens.sm ? "space-between" : "end",
                }}
            >

                <Col xs={0} sm={12} style={{display: "flex"}}>
                    <FineFoodsIcon
                        style={{
                            alignItems: "center"
                            // color: token.colorTextHeading,
                            // width: "100%",
                            // height: "auto",
                        }}
                    />
                </Col>

                <Col>
                    <Space size="middle" align="center">

                        <Text
                            ellipsis
                            strong
                            style={{
                                display: "flex",
                            }}
                        >
                            {user?.full_name}
                        </Text>
                        <Avatar
                            size="large"
                            src={user?.avatar}
                            alt={user?.full_name}
                        />
                    </Space>
                </Col>
            </Row>
        </AntdHeader>
    );
};